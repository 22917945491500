/**
 * You can add global styles to this file, and also import other style files
 *
 * @format
 */
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.d-none {
	display: none;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Inter";
}

:root {
	--navy-blue100: #102f53;
	--navy-blue200: #0a223f;
	--navy-blue300: #0c1c2e;
	--navy-blue400: #0f2642;
	--gray50: #f9fafb;
	--gray100: #d9d9d9;
	--gray200: #4b5563;
	--gray300: #d1d5db;
	--gray400: #9ca3af;
	--gray500: #6b7280;
	--gray600: #6e7b87;
	--white: #fff;
	--white50: #f6f8f8;
	--yellow: #fed200;
	--default-gray-300: #d3d9de;
	--default-gray-200: #edf0f2;
	--default-gray-400: #b3bdc6;
	--default-gray-400: #b3bdc6;
	--default-gray-500: #8d9aa5;
	--default-gray-600: #4b5563;
	--default-gray-800: #1f2937;
	--default-gray-900: #222426;
	--default-green-100: #f0fff4;
	--default-green-700: #2f855a;
	--default--red-700: #c53030;
	background: var(--default-gray-100, #f6f8f8);
	--sidebarWidth: 208px;
}

@media print {
	.logSheet-form-submit-btn,
	.logSheet-details__btns {
		display: none !important;
	}
	html,
	body {
		height: 100%;
		overflow: hidden;
	}

	app-log-sheet-form {
		position: fixed !important;
		background-color: #0a223f !important;
		z-index: 50;
		right: 0;
		top: 0 !important;
		bottom: 0 !important;
		width: 100vw !important;
		height: 100vh !important;
	}
}

.section-title {
	color: var(--navy-blue100);
	line-height: 2rem;
	font-size: 2rem;
	font-weight: 500;
}

.subtext {
	display: block;
	line-height: 1rem;
	font-size: 1rem;
	margin-top: 0.5rem;
	color: var(--gray200);
}

.card {
	border-radius: 20px;
	background: var(--white);
	box-shadow: 0px 0px 0px 2px var(--white50);
}

a {
	text-decoration: none;
}

/* TABLE STYLES */

.custom-table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 8px;
	overflow-y: scroll;
	margin-top: 1rem;
	overflow: visible;
}

.custom-table th {
	background: var(--white50);
}

.custom-table td {
	background: var(--white);
}

.custom-table tr th:not(:first-child, :last-child),
.custom-table tr td:not(:first-child, :last-child) {
	border-top: 1px solid var(--gray400);
	border-bottom: 1px solid var(--gray400);
}
.custom-table tr th:first-child,
.custom-table tr td:first-child {
	border: 1px solid var(--gray400);
	border-right: 0;
}
.custom-table tr th:last-child,
.custom-table tr td:last-child {
	border: 1px solid var(--gray400);
	border-left: 0;
}

.custom-table th:last-child,
.custom-table td:last-child {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}
.custom-table th:first-child,
.custom-table td:first-child {
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}

.custom-table td,
.custom-table th {
	font-weight: 400;
	position: relative;
}

.custom-table thead tr th:first-child {
	width: 40%;
}

.custom-table thead tr th:nth-child(2) {
	min-width: 180px;
}

.custom-table thead tr th:nth-child(2) {
	min-width: 180px;
}

.custom-table thead tr th:nth-child(3) {
	width: 10%;
}
.custom-table thead tr th:last-child {
	width: 5%;
}
.custom-table thead tr th:last-child img {
	visibility: hidden;
}

.custom-table thead tr th {
	text-align: left;
	color: var(--gray500);
	font-size: 16px;
	font-weight: 500;
}

.custom-table thead tr th {
	padding: 8px 24px;
}

.custom-table tr th:not(:nth-last-child(-n + 2))::after,
.custom-table tr td:not(:nth-last-child(-n + 2))::after {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	height: 13px;
	width: 1px;
	background: var(--gray500);
}

.custom-table tbody tr td {
	color: var(--gray600);
	font-size: 14px;
	line-height: 14px;
	padding: 16px 24px;
}

.custom-table tbody tr td:nth-child(-n + 2) {
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	max-width: 0;
	text-overflow: ellipsis;
}

.custom-table tr td {
	position: relative;
}
.custom-table tr td > img {
	cursor: pointer;
}

.custom-table td .title {
	color: var(--default-gray-600);
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	text-overflow: ellipsis;
    overflow: hidden;
	padding: 2px;
	line-height: 1.25;
}

.custom-table td:hover, .title:hover {
	text-overflow: clip !important;
    white-space: normal !important;
    text-wrap: balance !important;
}

.custom-table td .description {
	color: var(--default-gray-600);
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
	margin-top: 8px;
}

.custom-table__menu {
	z-index: 2;
	padding: 17px 0px;
	position: absolute;
	right: 0;
	width: 200px;
	/* height: 100px; */
	border-radius: 8px;
	background: #353b40;
	box-shadow: 0px 10.845232963562012px 10.845232963562012px 0px
			rgba(0, 0, 0, 0.04),
		0px 21.690465927124023px 27.113082885742188px 0px rgba(0, 0, 0, 0.1);
}
/* 
.custom-table__menu > div:first-child {
	
} */

.custom-table__menu > div:first-child {
	color: var(--white);
	font-size: 10.845px;
	font-weight: 500;
	line-height: 15.183px;
	text-transform: uppercase;
	padding: 4px 8px;
	border-radius: 4px;
	margin: 0 12px;
	background: var(--default-gray-900, #222426);
}

.custom-table__menu > div:last-child,
.custom-table__menu a {
	color: var(--white);
	padding: 13px 12px;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 15.183px;
	display: flex;
	align-items: center;
	gap: 13px;
	cursor: pointer;
}

.custom-table__menu a:first-of-type img {
	filter: invert(100%) brightness(180%);
	height: 15px;
	width: 15px;
}

/*  */

.underline {
	height: 2px;
	background: #525b64;
	width: 100%;
}

/* SECTION HEADER */

.section-header,
.section-header div:nth-child(2) {
	display: flex;
}

.section-header {
	justify-content: space-between;
	align-items: flex-end;
}

.section-header div:nth-child(2) {
	gap: 12px;
}

.section-header div:nth-child(2) button {
	border-radius: 100px;
	border: 0;
	background: var(--navy-blue400);
	cursor: pointer;
	padding: 6px 16px;
	color: var(--white);
	height: 100%;
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}
.section-header div:nth-child(2) button a {
	color: var(--white);
	height: 100%;
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
}

.custom-section {
	height: 100%;
	width: 100%;
	padding: 24px 50px;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow-y: auto;
}

/* DROPDOWN */
.dropdown-group {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0px 8px;
	flex-wrap: wrap;
	margin-top: 16px;
}

.dropdown-group > * {
	flex: 1 1 30%;
	/* min-width: 200px; */
}
.custom-dropdown {
	background: var(--white);
	margin-top: 8px;
	position: relative;
	width: 100%;
}

.custom-dropdown__default {
	border-radius: 4px;
	border: 1px solid var(--navy-blue100);
	padding: 12px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

/* .custom-dropdown__listing,
.custom-dropdown__listing .custom-dropdown__options {
	border: 1px solid var(--navy-blue100);
}

.custom-dropdown__form-field,
.custom-dropdown__form-field .custom-dropdown__options {
	border-color: 1px solid var(--default-gray-400) !important;
} */

.custom-dropdown__default.active {
	border-radius: 4px 4px 0 0;
	border-bottom-color: transparent;
}

.custom-dropdown__default.active img {
	transform: rotate(180deg);
}

.custom-dropdown__default span {
	color: var(--default-gray-900, #222426);
	font-size: 14px;
	font-style: normal;
	font-weight: 100;
	line-height: 14px;
}

.custom-dropdown__options {
	/* padding: 16px; */
	list-style-type: none;
	position: absolute;
	max-height: 150px;
	overflow-y: auto;
	width: 100%;
	background: var(--white);
	border: 1px solid var(--navy-blue100);
	border-top: 0;
	border-radius: 0 0 4px 4px;
	z-index: 3;
	/* max-width: 300px; */
}

.custom-dropdown__options li:not(:last-child) {
	/* margin-bottom: 19px; */
	padding: 16px 20px;
	transition: all ease-in-out 300ms;
}

.custom-dropdown__options li:last-child {
	padding: 16px 20px;
	transition: all ease-in-out 200ms;
}

.custom-dropdown__options li {
	cursor: pointer;
	color: var(--default-gray-800, #353b40);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
}

.custom-dropdown__options li:hover {
	color: var(--white);
	background-color: var(--navy-blue100);
}

.center {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* FORMS */

.required::after {
	content: " *";
	color: red;
}

.form-group {
	display: flex;
	margin-bottom: 2rem;
	gap: 24px;
	flex-wrap: wrap;
}
.row {
	display: flex;
	gap: 0 24px;
}

.col-1 > * {
	width: 100%;
}
.col-3 > * {
	flex: 1 1 32%;
}

.col-2 > * {
	flex: 1 1 48%;
}

.custom-label {
	display: block;
	color: #8d9aa5;
	font-size: 14px;
}

.custom-input {
	padding: 12px 16px;
	width: 100%;
	font-size: 14px;
	border-radius: 8px;
	border: 1px solid var(--default-gray-400, #b3bdc6);
	background: var(--default-shade-white, #fff);
	margin-top: 8px;
}

.single-list {
	margin-top: 10px;
	padding-left: 3px;
}

.fraction-field {
	text-align: center;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid var(--default-gray-400, #b3bdc6);
    background: var(--default-shade-white, #fff);
	width: 90px;
}

.fraction-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.fraction-slash {
	margin-left: 10px;
	margin-right: 10px;
	
}

.fraction-container input[type='number']::-webkit-inner-spin-button
{ 
    -webkit-appearance: none;
    margin: 0;
}

.multi-list {
	/* padding-left: 10px; */
	padding-left: 2px;
}

/* BUTTON */

.btn-group {
	display: flex;
	margin-top: 1.5rem;
}

.btn-text {
	color: #102f53;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 100% */
	text-decoration-line: underline;
	background-color: transparent;
	border: 0;
	cursor: pointer;
}

.btn-fill {
	background-color: var(--navy-blue400);
	color: var(--white);
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	width: 80%;
	gap: 8px;
	border: none;
	font-size: 1rem;
	cursor: pointer;
}

.btn-fill-yellow {
	background-color: var(--yellow);
	color: var(--white);
	display: flex;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	width: 80%;
	gap: 8px;
	border: none;
	font-size: 1rem;
	cursor: pointer;
}

.btn-outline {
	display: flex;
	width: 20%;
	padding: 8px 16px;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid var(--default-gray-800, #353b40);
	background-color: transparent;
	color: black;
	cursor: pointer;
	margin-right: 1.5rem;
}

.unit-log-component {
	flex: 1;
}

.disabled-input {
	background: var(--default-gray-200) !important;
	cursor: not-allowed;
	border: 0;
}

.disabled-btn {
	background: var(--default-gray-300) !important;
	cursor: not-allowed !important;
}

.error p {
	color: #c53030;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.error {
	display: flex;
	justify-items: center;
	gap: 4px;
	margin-top: 8px;
}
